import { default as backupsZgIa9TWJgxMeta } from "/app/frontend/src/pages/backups.vue?macro=true";
import { default as findingfieldsui29WtFIhSMeta } from "/app/frontend/src/pages/designs/[projectTypeId]/findingfields.vue?macro=true";
import { default as indexBcV06gsH67Meta } from "/app/frontend/src/pages/designs/[projectTypeId]/index.vue?macro=true";
import { default as notes1lljo3RWUfMeta } from "/app/frontend/src/pages/designs/[projectTypeId]/notes.vue?macro=true";
import { default as pdfdesignerpa1OsJJF2tMeta } from "/app/frontend/src/pages/designs/[projectTypeId]/pdfdesigner.vue?macro=true";
import { default as reportfieldsfuVToEdy0TMeta } from "/app/frontend/src/pages/designs/[projectTypeId]/reportfields.vue?macro=true";
import { default as _91projectTypeId_939dMEgBikuGMeta } from "/app/frontend/src/pages/designs/[projectTypeId].vue?macro=true";
import { default as index64g2t5Ct3VMeta } from "/app/frontend/src/pages/designs/index.vue?macro=true";
import { default as privateZd9ocD0B9kMeta } from "/app/frontend/src/pages/designs/private.vue?macro=true";
import { default as indexMf5jkORaCRMeta } from "/app/frontend/src/pages/index.vue?macro=true";
import { default as licensezxLCBvHvmuMeta } from "/app/frontend/src/pages/license.vue?macro=true";
import { default as autoAUBHU94pOcMeta } from "/app/frontend/src/pages/login/auto.vue?macro=true";
import { default as index6tsFOSXP2dMeta } from "/app/frontend/src/pages/login/index.vue?macro=true";
import { default as localTsalOUwb3lMeta } from "/app/frontend/src/pages/login/local.vue?macro=true";
import { default as callback3EiA7Q6aXcMeta } from "/app/frontend/src/pages/login/oidc/[authProviderId]/callback.vue?macro=true";
import { default as reauthnwza5tMdRrMeta } from "/app/frontend/src/pages/login/reauth.vue?macro=true";
import { default as _91noteId_93O3Chu7dCKAMeta } from "/app/frontend/src/pages/notes/personal/[noteId].vue?macro=true";
import { default as index1DPL1wATpSMeta } from "/app/frontend/src/pages/notes/personal/index.vue?macro=true";
import { default as personaljYYq1UwqsFMeta } from "/app/frontend/src/pages/notes/personal.vue?macro=true";
import { default as archivecDgAYAZlG7Meta } from "/app/frontend/src/pages/projects/[projectId]/archive.vue?macro=true";
import { default as designerhqz1YsUYmLMeta } from "/app/frontend/src/pages/projects/[projectId]/designer.vue?macro=true";
import { default as historyjTmSOIl5rAMeta } from "/app/frontend/src/pages/projects/[projectId]/history.vue?macro=true";
import { default as indexSawsiN7KwoMeta } from "/app/frontend/src/pages/projects/[projectId]/index.vue?macro=true";
import { default as _91historyDate_93IaWVETKT6qMeta } from "/app/frontend/src/pages/projects/[projectId]/notes/[noteId]/history/[historyDate].vue?macro=true";
import { default as indexxeJq2tqifWMeta } from "/app/frontend/src/pages/projects/[projectId]/notes/[noteId]/index.vue?macro=true";
import { default as indexTga67sANQcMeta } from "/app/frontend/src/pages/projects/[projectId]/notes/index.vue?macro=true";
import { default as notesivEEU8zD53Meta } from "/app/frontend/src/pages/projects/[projectId]/notes.vue?macro=true";
import { default as publishDpcUafriHgMeta } from "/app/frontend/src/pages/projects/[projectId]/publish.vue?macro=true";
import { default as _91historyDate_931ah68vjHKRMeta } from "/app/frontend/src/pages/projects/[projectId]/reporting/findings/[findingId]/history/[historyDate].vue?macro=true";
import { default as indexn4p8ktfFGqMeta } from "/app/frontend/src/pages/projects/[projectId]/reporting/findings/[findingId]/index.vue?macro=true";
import { default as indexcqKZLTJe2ZMeta } from "/app/frontend/src/pages/projects/[projectId]/reporting/index.vue?macro=true";
import { default as _91historyDate_93JXyNSK4JEtMeta } from "/app/frontend/src/pages/projects/[projectId]/reporting/sections/[sectionId]/history/[historyDate].vue?macro=true";
import { default as indexAC5wB9IXR9Meta } from "/app/frontend/src/pages/projects/[projectId]/reporting/sections/[sectionId]/index.vue?macro=true";
import { default as reportingOzsAQlMEoMMeta } from "/app/frontend/src/pages/projects/[projectId]/reporting.vue?macro=true";
import { default as _91projectId_93g827x3LaLwMeta } from "/app/frontend/src/pages/projects/[projectId].vue?macro=true";
import { default as _91archiveId_93MmIC1PRJWoMeta } from "/app/frontend/src/pages/projects/archived/[archiveId].vue?macro=true";
import { default as indexScrE0j62szMeta } from "/app/frontend/src/pages/projects/archived/index.vue?macro=true";
import { default as finishedYxzpNu9iJTMeta } from "/app/frontend/src/pages/projects/finished.vue?macro=true";
import { default as indexOZ0RFyXkAWMeta } from "/app/frontend/src/pages/projects/index.vue?macro=true";
import { default as newV3pJ8pIcZWMeta } from "/app/frontend/src/pages/projects/new.vue?macro=true";
import { default as _91historyDate_93ry9wFVcNwEMeta } from "/app/frontend/src/pages/templates/[templateId]/history/[historyDate].vue?macro=true";
import { default as index9s2xoFsBJ0Meta } from "/app/frontend/src/pages/templates/[templateId]/index.vue?macro=true";
import { default as fromfindingaPH1KL2e7qMeta } from "/app/frontend/src/pages/templates/fromfinding.vue?macro=true";
import { default as indexZJJx716aGUMeta } from "/app/frontend/src/pages/templates/index.vue?macro=true";
import { default as apitokensxE6nfZg71RMeta } from "/app/frontend/src/pages/users/[userId]/apitokens.vue?macro=true";
import { default as identitiesjIaI0LHVy0Meta } from "/app/frontend/src/pages/users/[userId]/identities.vue?macro=true";
import { default as indexgmy2EQd0ejMeta } from "/app/frontend/src/pages/users/[userId]/index.vue?macro=true";
import { default as mfaRfCUJxFGADMeta } from "/app/frontend/src/pages/users/[userId]/mfa.vue?macro=true";
import { default as reset_45password0vcTOiLbHKMeta } from "/app/frontend/src/pages/users/[userId]/reset-password.vue?macro=true";
import { default as _91userId_93pAPqCZuHcAMeta } from "/app/frontend/src/pages/users/[userId].vue?macro=true";
import { default as indext9a7DYRpPHMeta } from "/app/frontend/src/pages/users/index.vue?macro=true";
import { default as newmFBbp6MZGyMeta } from "/app/frontend/src/pages/users/new.vue?macro=true";
import { default as disableZGe3JI0Z4mMeta } from "/app/frontend/src/pages/users/self/admin/disable.vue?macro=true";
import { default as enablemD4rzmQWfIMeta } from "/app/frontend/src/pages/users/self/admin/enable.vue?macro=true";
import { default as apitokensNzrX0HbFb7Meta } from "/app/frontend/src/pages/users/self/apitokens.vue?macro=true";
import { default as indexqsOtAAd0dxMeta } from "/app/frontend/src/pages/users/self/index.vue?macro=true";
import { default as publickeysaNUZ8hwiZQMeta } from "/app/frontend/src/pages/users/self/publickeys.vue?macro=true";
import { default as securityFhHzVi7ffMMeta } from "/app/frontend/src/pages/users/self/security.vue?macro=true";
import { default as selfAUDD3dmxkeMeta } from "/app/frontend/src/pages/users/self.vue?macro=true";
export default [
  {
    name: "backups",
    path: "/backups",
    meta: backupsZgIa9TWJgxMeta || {},
    component: () => import("/app/frontend/src/pages/backups.vue").then(m => m.default || m)
  },
  {
    name: _91projectTypeId_939dMEgBikuGMeta?.name,
    path: "/designs/:projectTypeId()",
    component: () => import("/app/frontend/src/pages/designs/[projectTypeId].vue").then(m => m.default || m),
    children: [
  {
    name: "designs-projectTypeId-findingfields",
    path: "findingfields",
    component: () => import("/app/frontend/src/pages/designs/[projectTypeId]/findingfields.vue").then(m => m.default || m)
  },
  {
    name: "designs-projectTypeId",
    path: "",
    component: () => import("/app/frontend/src/pages/designs/[projectTypeId]/index.vue").then(m => m.default || m)
  },
  {
    name: "designs-projectTypeId-notes",
    path: "notes",
    component: () => import("/app/frontend/src/pages/designs/[projectTypeId]/notes.vue").then(m => m.default || m)
  },
  {
    name: "designs-projectTypeId-pdfdesigner",
    path: "pdfdesigner",
    component: () => import("/app/frontend/src/pages/designs/[projectTypeId]/pdfdesigner.vue").then(m => m.default || m)
  },
  {
    name: "designs-projectTypeId-reportfields",
    path: "reportfields",
    component: () => import("/app/frontend/src/pages/designs/[projectTypeId]/reportfields.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "designs",
    path: "/designs",
    meta: index64g2t5Ct3VMeta || {},
    component: () => import("/app/frontend/src/pages/designs/index.vue").then(m => m.default || m)
  },
  {
    name: "designs-private",
    path: "/designs/private",
    meta: privateZd9ocD0B9kMeta || {},
    component: () => import("/app/frontend/src/pages/designs/private.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexMf5jkORaCRMeta || {},
    component: () => import("/app/frontend/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "license",
    path: "/license",
    meta: licensezxLCBvHvmuMeta || {},
    component: () => import("/app/frontend/src/pages/license.vue").then(m => m.default || m)
  },
  {
    name: "login-auto",
    path: "/login/auto",
    meta: autoAUBHU94pOcMeta || {},
    component: () => import("/app/frontend/src/pages/login/auto.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: index6tsFOSXP2dMeta || {},
    component: () => import("/app/frontend/src/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "login-local",
    path: "/login/local",
    meta: localTsalOUwb3lMeta || {},
    component: () => import("/app/frontend/src/pages/login/local.vue").then(m => m.default || m)
  },
  {
    name: "login-oidc-authProviderId-callback",
    path: "/login/oidc/:authProviderId()/callback",
    meta: callback3EiA7Q6aXcMeta || {},
    component: () => import("/app/frontend/src/pages/login/oidc/[authProviderId]/callback.vue").then(m => m.default || m)
  },
  {
    name: "login-reauth",
    path: "/login/reauth",
    meta: reauthnwza5tMdRrMeta || {},
    component: () => import("/app/frontend/src/pages/login/reauth.vue").then(m => m.default || m)
  },
  {
    name: personaljYYq1UwqsFMeta?.name,
    path: "/notes/personal",
    component: () => import("/app/frontend/src/pages/notes/personal.vue").then(m => m.default || m),
    children: [
  {
    name: "notes-personal-noteId",
    path: ":noteId()",
    component: () => import("/app/frontend/src/pages/notes/personal/[noteId].vue").then(m => m.default || m)
  },
  {
    name: "notes-personal",
    path: "",
    component: () => import("/app/frontend/src/pages/notes/personal/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91projectId_93g827x3LaLwMeta?.name,
    path: "/projects/:projectId()",
    component: () => import("/app/frontend/src/pages/projects/[projectId].vue").then(m => m.default || m),
    children: [
  {
    name: "projects-projectId-archive",
    path: "archive",
    component: () => import("/app/frontend/src/pages/projects/[projectId]/archive.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-designer",
    path: "designer",
    component: () => import("/app/frontend/src/pages/projects/[projectId]/designer.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-history",
    path: "history",
    component: () => import("/app/frontend/src/pages/projects/[projectId]/history.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId",
    path: "",
    component: () => import("/app/frontend/src/pages/projects/[projectId]/index.vue").then(m => m.default || m)
  },
  {
    name: notesivEEU8zD53Meta?.name,
    path: "notes",
    meta: notesivEEU8zD53Meta || {},
    component: () => import("/app/frontend/src/pages/projects/[projectId]/notes.vue").then(m => m.default || m),
    children: [
  {
    name: "projects-projectId-notes-noteId-history-historyDate",
    path: ":noteId()/history/:historyDate()",
    component: () => import("/app/frontend/src/pages/projects/[projectId]/notes/[noteId]/history/[historyDate].vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-notes-noteId",
    path: ":noteId()",
    component: () => import("/app/frontend/src/pages/projects/[projectId]/notes/[noteId]/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-notes",
    path: "",
    component: () => import("/app/frontend/src/pages/projects/[projectId]/notes/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "projects-projectId-publish",
    path: "publish",
    meta: publishDpcUafriHgMeta || {},
    component: () => import("/app/frontend/src/pages/projects/[projectId]/publish.vue").then(m => m.default || m)
  },
  {
    name: reportingOzsAQlMEoMMeta?.name,
    path: "reporting",
    meta: reportingOzsAQlMEoMMeta || {},
    component: () => import("/app/frontend/src/pages/projects/[projectId]/reporting.vue").then(m => m.default || m),
    children: [
  {
    name: "projects-projectId-reporting-findings-findingId-history-historyDate",
    path: "findings/:findingId()/history/:historyDate()",
    component: () => import("/app/frontend/src/pages/projects/[projectId]/reporting/findings/[findingId]/history/[historyDate].vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-reporting-findings-findingId",
    path: "findings/:findingId()",
    component: () => import("/app/frontend/src/pages/projects/[projectId]/reporting/findings/[findingId]/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-reporting",
    path: "",
    component: () => import("/app/frontend/src/pages/projects/[projectId]/reporting/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-reporting-sections-sectionId-history-historyDate",
    path: "sections/:sectionId()/history/:historyDate()",
    component: () => import("/app/frontend/src/pages/projects/[projectId]/reporting/sections/[sectionId]/history/[historyDate].vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId-reporting-sections-sectionId",
    path: "sections/:sectionId()",
    component: () => import("/app/frontend/src/pages/projects/[projectId]/reporting/sections/[sectionId]/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: "projects-archived-archiveId",
    path: "/projects/archived/:archiveId()",
    component: () => import("/app/frontend/src/pages/projects/archived/[archiveId].vue").then(m => m.default || m)
  },
  {
    name: "projects-archived",
    path: "/projects/archived",
    meta: indexScrE0j62szMeta || {},
    component: () => import("/app/frontend/src/pages/projects/archived/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-finished",
    path: "/projects/finished",
    meta: finishedYxzpNu9iJTMeta || {},
    component: () => import("/app/frontend/src/pages/projects/finished.vue").then(m => m.default || m)
  },
  {
    name: "projects",
    path: "/projects",
    meta: indexOZ0RFyXkAWMeta || {},
    component: () => import("/app/frontend/src/pages/projects/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-new",
    path: "/projects/new",
    component: () => import("/app/frontend/src/pages/projects/new.vue").then(m => m.default || m)
  },
  {
    name: "templates-templateId-history-historyDate",
    path: "/templates/:templateId()/history/:historyDate()",
    component: () => import("/app/frontend/src/pages/templates/[templateId]/history/[historyDate].vue").then(m => m.default || m)
  },
  {
    name: "templates-templateId",
    path: "/templates/:templateId()",
    component: () => import("/app/frontend/src/pages/templates/[templateId]/index.vue").then(m => m.default || m)
  },
  {
    name: "templates-fromfinding",
    path: "/templates/fromfinding",
    component: () => import("/app/frontend/src/pages/templates/fromfinding.vue").then(m => m.default || m)
  },
  {
    name: "templates",
    path: "/templates",
    meta: indexZJJx716aGUMeta || {},
    component: () => import("/app/frontend/src/pages/templates/index.vue").then(m => m.default || m)
  },
  {
    name: _91userId_93pAPqCZuHcAMeta?.name,
    path: "/users/:userId()",
    component: () => import("/app/frontend/src/pages/users/[userId].vue").then(m => m.default || m),
    children: [
  {
    name: "users-userId-apitokens",
    path: "apitokens",
    component: () => import("/app/frontend/src/pages/users/[userId]/apitokens.vue").then(m => m.default || m)
  },
  {
    name: "users-userId-identities",
    path: "identities",
    component: () => import("/app/frontend/src/pages/users/[userId]/identities.vue").then(m => m.default || m)
  },
  {
    name: "users-userId",
    path: "",
    component: () => import("/app/frontend/src/pages/users/[userId]/index.vue").then(m => m.default || m)
  },
  {
    name: "users-userId-mfa",
    path: "mfa",
    component: () => import("/app/frontend/src/pages/users/[userId]/mfa.vue").then(m => m.default || m)
  },
  {
    name: "users-userId-reset-password",
    path: "reset-password",
    component: () => import("/app/frontend/src/pages/users/[userId]/reset-password.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "users",
    path: "/users",
    meta: indext9a7DYRpPHMeta || {},
    component: () => import("/app/frontend/src/pages/users/index.vue").then(m => m.default || m)
  },
  {
    name: "users-new",
    path: "/users/new",
    component: () => import("/app/frontend/src/pages/users/new.vue").then(m => m.default || m)
  },
  {
    name: selfAUDD3dmxkeMeta?.name,
    path: "/users/self",
    component: () => import("/app/frontend/src/pages/users/self.vue").then(m => m.default || m),
    children: [
  {
    name: "users-self-admin-disable",
    path: "admin/disable",
    component: () => import("/app/frontend/src/pages/users/self/admin/disable.vue").then(m => m.default || m)
  },
  {
    name: "users-self-admin-enable",
    path: "admin/enable",
    component: () => import("/app/frontend/src/pages/users/self/admin/enable.vue").then(m => m.default || m)
  },
  {
    name: "users-self-apitokens",
    path: "apitokens",
    component: () => import("/app/frontend/src/pages/users/self/apitokens.vue").then(m => m.default || m)
  },
  {
    name: "users-self",
    path: "",
    component: () => import("/app/frontend/src/pages/users/self/index.vue").then(m => m.default || m)
  },
  {
    name: "users-self-publickeys",
    path: "publickeys",
    component: () => import("/app/frontend/src/pages/users/self/publickeys.vue").then(m => m.default || m)
  },
  {
    name: "users-self-security",
    path: "security",
    component: () => import("/app/frontend/src/pages/users/self/security.vue").then(m => m.default || m)
  }
]
  }
]